<template>
  <v-data-table :headers="headers" :items="candidates"
                class="px-5"
                sort-by="id" sort-desc
                :loading="loading.load"
                loading-text="Загрузка... Пожалуйста подождите"
                :mobile-breakpoint="300"
                :page="filter.page"
                :height="tableHeight"
                fixed-header
                @pagination="pagination"
                hide-default-footer
                :server-items-length="count"
                :footer-props="{itemsPerPageOptions:[25]}">

    <template v-slot:top>
      <v-toolbar flat>
        <v-spacer></v-spacer>

        <CandidatesTableActions v-if="selected.length" class="d-none d-sm-block" />

        <CandidateCreateModal />

        <CandidateDeleteModal />
      </v-toolbar>
    </template>

    <!-- Header -->
    <template v-slot:header>
      <tr>
        <td class="border-bottom pa-4">
          <div>
            <v-checkbox :input-value="selected.length" @change="onChangeAllCheck"
                        :indeterminate="!!selected.length && selected.length !== count"
                        hide-details class="mt-auto" />
          </div>
        </td>

        <td class="border-bottom pa-3">
          <div>
            <v-text-field label="Фамилия и Имя"
                          append-icon="mdi-magnify"
                          class="font-sm"
                          :value="filter.name"
                          @input="debouncedSetFilterName"
                          dense
                          hide-details
                          outlined />
          </div>
        </td>

        <td class="border-bottom pa-3">
          <div>
            <v-text-field label="Электронная почта"
                          append-icon="mdi-magnify"
                          class="font-sm"
                          :value="filter.email"
                          @input="debouncedSetFilterEmail"
                          dense
                          hide-details
                          outlined />
          </div>
        </td>

        <td class="border-bottom pa-3">
          <div>
            <v-text-field label="Компания"
                          append-icon="mdi-magnify"
                          class="font-sm"
                          dense
                          :value="filter.company"
                          @input="debouncedSetFilterCompany"
                          hide-details
                          outlined />
          </div>
        </td>

        <td class="border-bottom pa-3">
          <div>
            <v-text-field label="Должность"
                          append-icon="mdi-magnify"
                          class="font-sm"
                          dense
                          :value="filter.position"
                          @input="debouncedSetFilterPosition"
                          hide-details
                          outlined />
          </div>
        </td>

        <td class="border-bottom pa-3">
          <!-- Дата регистрации -->
          <v-menu
            v-model="datePickerShow"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="dateJoinedFilter.join(' - ')"
                label="Дата регистрации"
                append-icon="mdi-calendar"
                :prepend-inner-icon="dateJoinedFilter.length ? 'mdi-close' : null"
                @click:prepend-inner="clearJoinedDateFilter"
                class="font-sm"
                dense
                readonly
                outlined
                hide-details
                v-bind="attrs"
                v-on="on"
              />
            </template>

            <v-date-picker
              v-model="dateJoinedFilter"
              range
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="cancelJoinedDateFilter"
              >
                Отменить
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="applyJoinedDateFilter"
                :disabled="dateJoinedFilter.length !== 2"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>

        </td>

        <td class="border-bottom pa-3">
          <div class="other-width-col">
            <v-select v-model="selectedTestingStatuses"
                      :items="testingStatuses"
                      item-text="text" item-value="value"
                      label="Статус тестирования"
                      class="font-sm"
                      hide-details
                      :menu-props="{ top: false }"
                      dense multiple outlined>

              <template v-slot:prepend-item>
                <v-list-item ripple @click="testingSelectedAllToggle">
                  <v-list-item-action>
                    <v-icon :color="selectedTests.length > 0 ? 'indigo darken-4' : ''">
                      {{ iconTestingStatuses }}
                    </v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>Все</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <template v-slot:append-item>
                <div class="text-center py-2">
                  <v-btn color="primary" small class="px-10" @click.prevent="loadCandidates">Применить</v-btn>
                </div>
              </template>
            </v-select>
          </div>
        </td>

        <td class="border-bottom pa-3"></td>

        <td class="border-bottom pa-3"></td>

        <td class="border-bottom pa-3"></td>
      </tr>
    </template>

    <!--  Cells  -->
    <template v-slot:item.check="{ item }">
      <div>
        <v-checkbox :input-value="selected.some((id) => id === item.id)"
                    @change="setSelected(item.id)" hide-details class="mt-auto" />
      </div>
    </template>

    <template v-slot:item.fullname="{ item }">
      <div class="d-flex justify-space-between align-center">

        <router-link class="text-primary mr-2" :to="{ name: 'candidates-id', params: { id: item.id } }">
          {{ item.last_name }} {{ item.first_name }}
        </router-link>
        <div class="status" :class="{active: item.status}" />
      </div>
    </template>

    <template #item.company="{ item }">
      {{
        (item.creator.manager && item.creator.manager.company)
        || (item.creator.admin && `${item.creator.admin.first_name} ${item.creator.admin.last_name}`)
      }}
    </template>

    <template v-slot:item.date_joined="{ item }">
      <div class="d-flex justify-space-between align-center">
        {{
          $moment(item.date_joined)
            .format('DD.MM.YYYY')
        }}
      </div>
    </template>

    <template v-slot:item.testing_status="{ item }">
      <div class="badge font-xs w-100" :class="testingStatusBadgeClass(item)">
        {{ testingStatus(item) }}
      </div>
    </template>

    <template v-slot:item.sendTest="{ item }">
      <CreateTestingSessionButton :data="item"
                                  :loading="loadingSession.createSession"
                                  :sendTests="sendTests" />
    </template>

    <template v-slot:item.comment="{ item }">
      <div class="pa-2 other-width-col">
        <v-tooltip v-if="item.comment" top>
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex" v-bind="attrs" v-on="on">
              <v-text-field label="Комментарий"
                            class="font-xs"
                            dense
                            :value="item.comment"
                            @input="debouncedSendComment($event, item)"
                            hide-details
                            outlined />
            </div>
          </template>
          <span>{{ item.comment }}</span>
        </v-tooltip>

        <v-text-field v-else label="Комментарий"
                      class="font-xs"
                      dense
                      :value="item.comment"
                      @input="debouncedSendComment($event, item)"
                      hide-details
                      outlined />
      </div>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon @click="deleteCandidateDialog(item)">mdi-delete</v-icon>
    </template>

    <template v-slot:no-data>
      Кандидатов не найдено.
    </template>

    <template v-slot:footer>
      <div class="text-center py-6">
        <v-pagination v-if="paginationData"
                      :value="filter.page" @input="setFilterPage"
                      :length="paginationData.pageCount" />
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'lodash';
import { TESTING_STATUSES, TESTING_STATUSES_TITLES } from '@/common/constants/testingStatuses';
import CandidatesTableActions from '@/components/candidates/table/CandidatesTableActions';
import CreateTestingSessionButton from '@/components/candidates/table/CreateTestingSessionButton';
import CandidateCreateModal from '@/components/candidates/CandidateCreateModal';
import CandidateDeleteModal from '@/components/candidates/CandidateDeleteModal';

export default {
  name: 'CandidatesTable',
  components: {
    CandidatesTableActions,
    CreateTestingSessionButton,
    CandidateCreateModal,
    CandidateDeleteModal,
  },
  data: () => ({
    tableHeight: 400,
    testingStatuses: Object.entries(TESTING_STATUSES_TITLES)
      .map((s) => ({
        text: s[1],
        value: s[0],
      })),
    selectedTests: [],
    headers: [
      {
        text: '',
        value: 'check',
        sortable: false,
        width: '60px',
      },
      {
        text: 'Фамилия и Имя',
        value: 'fullname',
        sortable: false,
        width: '280px',
      },
      {
        text: 'Электронная почта',
        value: 'email',
        sortable: false,
        width: '280px',
      },
      {
        text: 'Компания',
        value: 'company',
        sortable: false,
        width: '280px',
      },
      {
        text: 'Должность',
        value: 'position',
        sortable: false,
        width: '280px',
      },
      {
        text: 'Дата регистрации',
        value: 'date_joined',
        sortable: false,
        width: '280px',
      },
      {
        text: 'Статус тестирования',
        value: 'testing_status',
        sortable: false,
        width: '220px',
      },
      {
        text: '',
        value: 'sendTest',
        sortable: false,
        width: '220px',
      },
      {
        text: '',
        value: 'comment',
        sortable: false,
        width: '280px',
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
        align: 'end',
      },
    ],
    paginationData: null,
    datePickerShow: false,
    dateJoinedFilter: [],

    datePickerDateFormat: 'YYYY-MM-DD',
  }),
  computed: {
    ...mapGetters({
      loading: 'users/candidates/loading',
      candidates: 'users/candidates/candidates',
      selected: 'users/candidates/selected',
      filter: 'users/candidates/filter',
      count: 'users/candidates/count',
      loadingSession: 'testing/session/loading',
    }),
    selectedTestingStatuses: {
      get() {
        return this.filter.testing_status;
      },
      set(val) {
        this.setFilterTestingStatus(val);
      },
    },
    selectedAllTestingStatuses() {
      return this.selectedTestingStatuses.length === this.testingStatuses.length;
    },
    selectedSomeTestingStatus() {
      return this.selectedTestingStatuses.length > 0 && !this.selectedAllTestingStatuses;
    },
    iconTestingStatuses() {
      if (this.selectedAllTestingStatuses) return 'mdi-checkbox-marked';
      if (this.selectedSomeTestingStatus) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },

  },
  methods: {
    ...mapActions({
      setSelected: 'users/candidates/setSelected',
      allSelected: 'users/candidates/allSelected',
      clearSelected: 'users/candidates/clearSelected',
      setFilterPage: 'users/candidates/setFilterPage',
      setFilterName: 'users/candidates/setFilterName',
      setFilterJoinedDatesRange: 'users/candidates/setFilterJoinedDatesRange',
      setFilterEmail: 'users/candidates/setFilterEmail',
      setFilterPosition: 'users/candidates/setFilterPosition',
      setFilterCompany: 'users/candidates/setFilterCompany',
      setFilterTestingStatus: 'users/candidates/setFilterTestingStatus',
      setFilterAll: 'users/candidates/setFilterAll',
      loadCandidates: 'users/candidates/loadCandidates',
      deleteCandidateDialog: 'users/candidates/deleteCandidateDialog',
      editCandidateComment: 'users/candidates/editCandidateComment',
      setCandidateTestingStatus: 'users/candidates/setCandidateTestingStatus',

      createTestingSession: 'testing/session/createTestingSession',
    }),
    initialize() {
      this.setFilterAll(this.$route.query);
    },
    pagination(data) {
      this.paginationData = data;
    },
    onChangeAllCheck(bool) {
      if (bool) {
        this.allSelected();
      } else {
        this.clearSelected();
      }
    },
    testingSelectedAllToggle() {
      this.$nextTick(() => {
        if (this.selectedAllTestingStatuses) {
          this.selectedTestingStatuses = [];
        } else {
          this.selectedTestingStatuses = this.testingStatuses.map((s) => s.value);
        }
      });
    },
    // eslint-disable-next-line func-names
    debouncedSetFilterName: debounce(function (val) {
      this.setFilterName(val);
    }, 500),
    // eslint-disable-next-line func-names
    debouncedSetFilterEmail: debounce(function (val) {
      this.setFilterEmail(val);
    }, 500),
    // eslint-disable-next-line func-names
    debouncedSetFilterPosition: debounce(function (val) {
      this.setFilterPosition(val);
    }, 500),
    // eslint-disable-next-line func-names
    debouncedSetFilterCompany: debounce(function (val) {
      this.setFilterCompany(val);
    }, 500),
    testingStatus(item) {
      return TESTING_STATUSES_TITLES[item.testing_status];
    },
    clearJoinedDateFilter() {
      this.dateJoinedFilter = [];
      this.setFilterJoinedDatesRange(this.dateJoinedFilter);
      this.datePickerShow = false;
    },
    cancelJoinedDateFilter() {
      if (this.filter.from_date_joined && this.filter.to_date_joined) {
        this.dateJoinedFilter = [this.filter.from_date_joined, this.filter.to_date_joined];
      } else {
        this.dateJoinedFilter = [];
      }
      this.datePickerShow = false;
    },
    applyJoinedDateFilter() {
      this.dateJoinedFilter = this.dateJoinedFilter
        .sort((a, b) => (this.$moment(a, this.datePickerDateFormat)
          .isBefore(this.$moment(b, this.datePickerDateFormat)) ? -1 : 1));

      this.setFilterJoinedDatesRange(this.dateJoinedFilter);
      this.datePickerShow = false;
    },
    testingStatusBadgeClass(item) {
      switch (item.testing_status) {
        case TESTING_STATUSES.COMPLETED:
          return 'badge-success';
        case TESTING_STATUSES.NOT_COMPLETED:
          return 'badge-primary';
        case TESTING_STATUSES.IN_PROGRESS:
          return 'badge-warning';
        case TESTING_STATUSES.NOT_SEND:
          return 'badge-error';
        case TESTING_STATUSES.NOT_STARTED:
          return '';
        default:
          return 'd-none';
      }
    },

    // eslint-disable-next-line func-names
    debouncedSendComment: debounce(function (text, item) {
      this.editCandidateComment({
        id: item.id,
        text,
      });
    }, 1000),

    async sendTests(data) {
      await this.createTestingSession(data)
        .then(({
          candidate,
          response,
        }) => {
          this.setCandidateTestingStatus({
            id: candidate,
            data: response,
          });
        });
    },
  },
  watch: {
    filter: {
      handler(val) {
        const query = {};
        for (const [key, value] of Object.entries(val)) {
          if ((value || value === 0) && !Array.isArray(value)) {
            query[key] = value;
          }
        }
        this.$router.replace({
          name: this.$route.name,
          query,
        })
          .catch(() => {});
      },
      deep: true,
    },
  },
  created() {
    this.initialize();
  },
  mounted() {
    const height = document.documentElement.clientHeight - 270;
    this.tableHeight = Math.max(height, this.tableHeight);
  },
};
</script>
<style lang="scss">
.v-list.v-sheet {
  padding: 0 !important;
}

.v-list-item__content {
  padding: 0 !important;
}

.v-list-item__action {
  margin-top: 0;
  margin-bottom: 0;
}

.v-select__selections {
  flex-wrap: nowrap;
}

.other-width-col {
  width: 13rem;
}
</style>
