<template>
  <v-menu offset-y :close-on-content-click="false" ref="menu">
    <template v-slot:activator="{ on, attrs }">
      <v-btn elevation="0" class="font-xs" v-bind="attrs" v-on="on">
        Отправить тест
        <v-icon v-if="attrs['aria-expanded']">mdi-chevron-down</v-icon>
        <v-icon v-else>mdi-chevron-up</v-icon>
      </v-btn>
    </template>

    <v-list :disabled="loading">
      <v-list-item ripple @click="setSelectedTests('ALL')">
        <v-list-item-action class="mr-4">
          <v-icon class="icon-sm"
                  :color="selectedTests.length < tests.length ? '' : 'primary'">
            {{ iconSelectedTests }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="font-sm">
            Все
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-for="test in tests" :key="test" @click="setSelectedTests(test)">
        <v-list-item-action class="mr-4">
          <v-icon class="icon-sm" :color="isTestSelected(test) ? 'primary' : ''">
            {{isTestSelected(test) ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'}}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="font-sm">
            {{ getTestTitle(test) }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <div class="d-flex px-4 py-2">
        <v-btn color="primary" class="mx-auto w-100 btn-disabled-primary"
               :disabled="!selectedTests.length || loading"
               :loading="loading"
               small
               @click.stop="send">
          Отправить
        </v-btn>
      </div>
    </v-list>
  </v-menu>
</template>

<script>

import { TEST_TYPES, TEST_TYPES_TITLES } from '@/common/constants/testTypes';
import { mapActions } from 'vuex';

export default {
  name: 'CreateTestingSessionButton',
  props: {
    data: {
      type: Object,
      required: true,
    },
    sendTests: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    testingStatuses: Object.values(TEST_TYPES_TITLES),
    tests: Object.values(TEST_TYPES),
    selectedTests: [],

  }),
  computed: {
    allTestsSelected() {
      return this.selectedTests.length === this.tests.length;
    },
    notAllTestsSelected() {
      return this.selectedTests.length > 0 && !this.allTestsSelected;
    },
    iconSelectedTests() {
      if (this.allTestsSelected) return 'mdi-checkbox-marked';
      if (this.notAllTestsSelected) return 'mdi-close-box';
      return 'mdi-checkbox-blank-outline';
    },
  },
  methods: {
    ...mapActions({
    }),
    isTestSelected(test) {
      return this.selectedTests.some((t) => t === test);
    },
    getTestTitle(test) {
      return TEST_TYPES_TITLES[test];
    },
    setSelectedTests(val) {
      if (val === 'ALL') {
        if (this.selectedTests.length) {
          this.selectedTests = [];
        } else {
          this.selectedTests = this.tests;
        }
        return;
      }
      if (this.selectedTests.some((t) => t === val)) {
        this.selectedTests = this.selectedTests.filter((t) => t !== val);
      } else {
        this.selectedTests.push(val);
      }
    },

    send() {
      const data = {
        tests: this.selectedTests,
        candidate: this.data.id,
      };
      this.sendTests(data).then(() => {
        this.selectedTests = [];
        this.$refs.menu.isActive = false;
      });
    },
  },
};
</script>

<style scoped>

</style>
