var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0 mr-4 icon",attrs:{"color":"primary","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#9BA1B5"}},[_vm._v("mdi-share")])],1)]}}])},[_c('span',[_vm._v("Поделиться")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0 mr-4 icon",attrs:{"color":"primary","outlined":"","loading":_vm.loading.loadResults},on:{"click":_vm.exportToTable}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#9BA1B5"}},[_vm._v("mdi-upload")])],1)]}}])},[_c('span',[_vm._v("Выгрузить в Excel")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0 mr-4 icon",attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.dialogDeleteShow = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#9BA1B5"}},[_vm._v("mdi-delete")])],1)]}}])},[_c('span',[_vm._v("Удалить")])]),_c('DialogDelete',{model:{value:(_vm.dialogDeleteShow),callback:function ($$v) {_vm.dialogDeleteShow=$$v},expression:"dialogDeleteShow"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }