<template>
  <div class="page">
    <div class="row">
      <div class="col-7 d-flex align-center">
        <h1 class="font-lg">Кандидаты</h1>
      </div>
      <div class="col-5 d-md-none d-flex align-center">
        <v-btn class="ml-auto mr-2 d-sm-none" small fab @click="setDialogCreateShow(true)">
          <v-icon small>mdi-timer</v-icon>
        </v-btn>
        <v-btn color="primary d-sm-none" small fab @click="setDialogCreateShow(true)">
          <v-icon small>mdi-account-plus</v-icon>
        </v-btn>

        <v-btn class="ml-auto mr-2 d-none d-sm-block" fab @click="setDialogCreateShow(true)">
          <v-icon small>mdi-timer</v-icon>
        </v-btn>
        <v-btn color="primary d-none d-sm-block" fab @click="setDialogCreateShow(true)">
          <v-icon small>mdi-account-plus</v-icon>
        </v-btn>
      </div>
    </div>

    <div class="row d-none d-md-flex mb-8">
      <div class="col-md-10 col-lg-9">
          <ExpressSendTest />
      </div>

      <div class="col-md-2 col-lg-3 d-flex align-center">
        <v-btn class="ml-auto" color="primary" fab @click="setDialogCreateShow(true)">
          <v-icon>mdi-account-plus</v-icon>
        </v-btn>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <CandidatesTable />
      </div>
    </div>

    <v-speed-dial v-if="selected.length" v-model="candidatesActionsFAB"
                  class="button-fab d-sm-none"
                  bottom right direction="top"
                  transition="slide-y-reverse-transition">
      <template v-slot:activator>
        <v-btn v-model="candidatesActionsFAB" color="#25325F" dark fab>
          <v-icon v-if="candidatesActionsFAB">mdi-close</v-icon>
          <v-icon v-else>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark small color="primary" class="icon bg-main" outlined>
        <v-icon color="#9BA1B5">mdi-share</v-icon>
      </v-btn>
      <v-btn fab dark small color="primary" class="icon bg-main" outlined
             :loading="loading.loadResults" @click="exportToTable">
        <v-icon color="#9BA1B5">mdi-upload</v-icon>
      </v-btn>
      <v-btn fab dark small color="primary" class="icon bg-main" @click="dialogDeleteShow = true" outlined>
        <v-icon color="#9BA1B5">mdi-delete</v-icon>
      </v-btn>
    </v-speed-dial>

    <DialogDelete v-model="dialogDeleteShow"/>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import CandidatesTable from '@/components/candidates/table/CandidatesTable';
import ExpressSendTest from '@/components/candidates/ExpressSendTest';
import exportToTableMixin from '@/components/candidates/table/exportToTableMixin';
import DialogDelete from '@/components/candidates/DialogDelete';

export default {
  name: 'Candidates',
  mixins: [exportToTableMixin],
  metaInfo: {
    title: 'Кандидаты',
  },
  components: {
    ExpressSendTest,
    CandidatesTable,
    DialogDelete,
  },
  data: () => ({
    candidatesActionsFAB: false,
    dialogDeleteShow: false,
  }),
  computed: {
    ...mapGetters({
      loading: 'users/candidates/loading',
      selected: 'users/candidates/selected',
    }),
  },
  methods: {
    ...mapActions({
      setSelected: 'users/candidates/setSelected',
      setDialogCreateShow: 'users/candidates/setDialogCreateShow',
    }),
  },
  beforeDestroy() {
    this.setSelected(null);
  },
};
</script>
